import OrientationPopup from "components/OrientationPopup";
import ReferencePopup from "components/ReferencePopup";
import { AuthContext } from "context/Auth";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";

const Popups = () => {
  const { profile } = useContext(AuthContext);

  const [isOrientationPopupVisible, setIsOrientationPopupVisible] =
    useState(false);
  const [isReferalPopupVisible, setIsReferencePopupVisible] = useState(false);

  const [referencePopupNumber, setReferencePopupNumber] = useState(0);

  useEffect(() => {
    const orientationPopupLastClosed = Date.parse(
      localStorage.getItem("orientation-popup"),
    );

    // if orientation popup was closed more than 3 hours ago, show it again
    // if profile?.orientation?.progress is not available, show the popup
    // if profile?.orientation?.progress is available but length is not 10, show the popup
    // if orientationPopupLastClosed is NaN, show the popup
    if (
      (isNaN(orientationPopupLastClosed) ||
        Date.now() - orientationPopupLastClosed > 3 * 60 * 60 * 1000) &&
      (!profile?.orientation?.progress ||
        (profile?.orientation?.progress &&
          Object.keys(profile.orientation.progress).length !== 10)) &&
      profile?.tour
    ) {
      // Show the orientation popup
      setIsOrientationPopupVisible(true);
    }
  }, []);

  useEffect(() => {
    const dashboardCreatedAt = profile?.createdAt.toDate();
    const popupLastClosed = Date.parse(localStorage.getItem("reference-popup"));
    const lastReferencePopupClosed = localStorage.getItem(
      "closed-reference-popup",
    );

    if (lastReferencePopupClosed === "3") {
      return;
    }

    if (dayjs().diff(dashboardCreatedAt, "days") > 30) {
      setIsReferencePopupVisible(true);
      setReferencePopupNumber(3);
    } else if (
      dayjs().diff(dashboardCreatedAt, "hours") > 24 &&
      lastReferencePopupClosed === null
    ) {
      setIsReferencePopupVisible(true);
      setReferencePopupNumber(1);
    } else if (
      dayjs().diff(popupLastClosed, "days") > 5 &&
      lastReferencePopupClosed === "1"
    ) {
      setIsReferencePopupVisible(true);
      setReferencePopupNumber(2);
    }
  }, []);

  const ReferencePopupMap = {
    1: (
      <ReferencePopup
        heading="Your support can give underprivileged children access to quality education."
        button="Refer This Internship to a Friend"
        color="secondary"
        popupNumber="1"
        setIsReferencePopupVisible={setIsReferencePopupVisible}
      />
    ),
    2: (
      <ReferencePopup
        heading="Loving your impact here? Let a friend join the mission!"
        button="Refer A Friend"
        color="secondary"
        popupNumber="2"
        setIsReferencePopupVisible={setIsReferencePopupVisible}
      />
    ),
    3: (
      <ReferencePopup
        heading="Your journey made a difference. Know someone who'd love to contribute too?"
        button="Refer A Friend"
        color="primary"
        popupNumber="3"
        setIsReferencePopupVisible={setIsReferencePopupVisible}
      />
    ),
  };
  return (
    <>
      {isOrientationPopupVisible && (
        <OrientationPopup
          setIsOrientationPopupVisible={setIsOrientationPopupVisible}
        />
      )}

      {!isOrientationPopupVisible &&
        isReferalPopupVisible &&
        ReferencePopupMap[referencePopupNumber]}
    </>
  );
};

export default Popups;
