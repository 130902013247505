import closeIcon from "assets/images/icons/close.png";
import bellIcon from "assets/images/icons/bell.png";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "context/Auth";

const ReferencePopup = ({
  setIsReferencePopupVisible,
  heading,
  button,
  color,
  popupNumber,
}) => {
  const { profile } = useContext(AuthContext);
  return (
    <div className="absolute top-0 left-0 h-full w-full bg-opacity-50 bg-black z-[11] p-5 flex justify-center items-center">
      <div className="bg-white w-full rounded-lg">
        <div
          className={`${
            color === "primary" ? "bg-primary" : "bg-secondary"
          } rounded-lg rounded-b-md p-5 flex justify-center flex-col gap-5`}
        >
          <div className="justify-end flex">
            <img
              src={bellIcon}
              alt="bell"
              className="h-20 absolute left-1/2 -translate-x-1/2 -translate-y-2/3"
            />
            <button
              className={`h-6 w-6 p-3`}
              style={{
                background: `url(${closeIcon})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
              onClick={() => {
                // localstorage set current time
                localStorage.setItem("reference-popup", new Date());
                localStorage.setItem("closed-reference-popup", popupNumber);

                setIsReferencePopupVisible(false);
              }}
            ></button>
          </div>

          <h3 className="text-white text-lg font-bold text-center">
            {heading}
          </h3>
          <Link
            to={`https://api.whatsapp.com/send?text=Hey%2C%20check%20out%20this%20Internship%20opportunity%20https%3A%2F%2Fmuskurahat.org.in%2Finternship-application%3Futm_source%3Dref-${profile?.referenceCode}`}
            className={`bg-white ${
              color === "primary" ? "text-secondary" : "text-primary"
            }  text-sm rounded-md font-bold self-center text-center px-3 py-2`}
            target="_blank"
            rel="noreferrer"
          >
            {button}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default ReferencePopup;
